import { createAuthLink, AuthOptions } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { getAccessToken } from './Auth'
import stackOutputs from './stack-outputs.json';

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { ApolloProviderProps } from "@apollo/client/react/context";

/* The HTTPS endpoint of the AWS AppSync API 
(e.g. *https://aaaaaaaaaaaaaaaaaaaaaaaaaa.appsync-api.us-east-1.amazonaws.com/graphql*). 
[Custom domain names](https://docs.aws.amazon.com/appsync/latest/devguide/custom-domain-name.html) can also be supplied here (e.g. *https://api.yourdomain.com/graphql*). 
Custom domain names can have any format, but must end with `/graphql` 
(see https://graphql.org/learn/serving-over-http/#uris-routes). */
const url = stackOutputs.PassageGraphApiUrl;


const region = stackOutputs.AwsRegion;

const auth : AuthOptions = {
  type: "AMAZON_COGNITO_USER_POOLS",
  //apiKey: stackOutputs.graphqlApiKey,
  jwtToken: async () => getAccessToken() || "invalid", // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  // credentials: async () => credentials, // Required when you use IAM-based auth.
};

const httpLink = new HttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const ApolloWrapper = ({ children } : ApolloProviderProps<InMemoryCache>) => {
  return <ApolloProvider client={client} children={children}></ApolloProvider>;
};

export default ApolloWrapper;