import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Fade from "@mui/material/Fade"
import CircularProgress from "@mui/material/CircularProgress"

import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';

import {StandardTextFieldProps} from "@mui/material/TextField"

interface EditableTextFieldProps extends StandardTextFieldProps {
    onCommitText: (text:string) => void
    onCancel?: () => void
    updating: boolean
    unsubmittedText: string
    onUnsubmittedTextChange: (text:string) => void
  }
  
export default function EditableTextField(props : EditableTextFieldProps) 
{
    const {onCommitText, onCancel, updating, unsubmittedText, onUnsubmittedTextChange, ...rest} = props;

    const [isEditing, setIsEditing] = useState(false);
    

    return (
        <React.Fragment>
        <TextField {...rest} value={unsubmittedText} onChange={(event) =>onUnsubmittedTextChange(event.target.value)} disabled={!isEditing} InputProps={{endAdornment:<InputAdornment position="end">
                <Stack direction="row" spacing={1}>
                    {!updating && isEditing && <IconButton onClick={() => {onCommitText && onCommitText(unsubmittedText); setIsEditing(false);}} edge="end"> <DoneIcon /> </IconButton> }
                    {!updating && isEditing && <IconButton onClick={() => {onCancel && onCancel(); setIsEditing(false);}} edge="end"> <CancelIcon /> </IconButton> }
                    {!updating && !isEditing && <IconButton onClick={() => {setIsEditing(true);}} edge="end"> <EditIcon /> </IconButton> }
                    {updating && <Fade in={updating} style={{transitionDelay: updating ? '600ms' : '0ms',}} unmountOnExit><CircularProgress /></Fade>}
                </Stack>
            </InputAdornment>}}
        />
        </React.Fragment>
    );
}