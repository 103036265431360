import React, { useEffect, useState } from 'react'
import stackOutputs from './stack-outputs.json';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

//import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'


import useFetch from './hooks/useFetch'

interface VerifyResponse {
    verifyStatus: string
}

const VerifyEmergencyContact = () => 
{
    const { data, error } = useFetch<VerifyResponse>(stackOutputs.FrontendHttpApiUrl + "/verify" + window.location.search);

    const buttonSx = {
        ...((data && data.verifyStatus !== "failed") && {bgcolor: "success.main", '&:hover': {bgcolor: "success.main"}}),
        ...((data && data.verifyStatus === "failed") && {bgcolor: "error.main", '&:hover': {bgcolor: "error.main"}}),
        ...((error) && {bgcolor: "error.main", '&:hover': {bgcolor: "error.main"}})
    };

    console.log(data);

    return (
        <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Verifying Emergency Contact"}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText> */}
          <Stack alignSelf="center" justifyContent="center" alignItems="center" spacing={1}>
            <Box sx={{ m: 1, position: 'relative' }}>
                <Fab color="primary" sx={buttonSx} disableFocusRipple disableRipple>
                    {data && data.verifyStatus === "complete" && <CheckIcon />}
                    {data && data.verifyStatus === "alreadyComplete" && <CheckIcon />}
                    {((data && data.verifyStatus === "failed") || error) && <PriorityHighIcon />}
                </Fab>
                {!data && (
                    <CircularProgress size={68} sx={{color: "primary", position: 'absolute', top: -6, left: -6, zIndex: 1,}}
                    />
                )}
            </Box>
            {data && data.verifyStatus === "complete" && <Typography variant="subtitle1" color="success.main">Verification Complete</Typography>}
            {data && data.verifyStatus === "alreadyComplete" && <Typography variant="subtitle1" color="success.main">Already Verified</Typography>}
            {((data && data.verifyStatus === "failed") || error) && <Typography variant="subtitle1" color="error.main">Verification Failed</Typography>}
            {(!data && !error) && <Typography variant="subtitle1" color="primary">Verifying...</Typography>}
        </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>window.location.replace("/")} autoFocus disabled={!data&&!error}>Close</Button>
        </DialogActions>
      </Dialog>
    );
}

export default VerifyEmergencyContact