import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import EditableTextField from "./EditableTextField";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Skeleton from "@mui/material/Skeleton"
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Unstable_Grid2';

import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
//import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useQuery, useMutation, gql } from "@apollo/client";
import OutlinedInputWrapper from './OutlinedInputWrapper'

const getUserProfileMeGql = gql`
  query UserProfileMe{
    userProfileMe {
        userId
        email
        checkinEmailEndpoint
        emergencyMessage
        verifiedEmergencyContacts
        unverifiedEmergencyContacts
    }
  }
`;

const updateEmergencyMessageGql = gql`
  mutation UpdateEmergencyMessage($message: String!){
    userUpdateEmergencyMessage(emergencyMessage: $message) {
        userId
        email
        checkinEmailEndpoint
        emergencyMessage
        verifiedEmergencyContacts
        unverifiedEmergencyContacts
    }
  }
`;

const addEmergencyContactGql = gql`
  mutation AddEmergencyContact($email: AWSEmail!){
    userAddEmergencyContact(email: $email) {
        userId
        email
        checkinEmailEndpoint
        emergencyMessage
        verifiedEmergencyContacts
        unverifiedEmergencyContacts
    }
  }
`;

export default function Profile() 
{
    const profile = useQuery(getUserProfileMeGql, {onCompleted:(data)=>setEditedEmergencyMessage(data.userProfileMe.emergencyMessage ? data.userProfileMe.emergencyMessage : "If you're reading this message then...")});
    const [updateEmergencyMessage, updateEmergencyMessageData] = useMutation(updateEmergencyMessageGql);
    const [addEmergencyContact, addEmergencyContactData] = useMutation(addEmergencyContactGql);
    const [requestVerifyEmergencyContact, requestVerifyEmergencyContactData] = useMutation(addEmergencyContactGql);
    
    const [editedEmergencyMessage, setEditedEmergencyMessage] = useState("");
    const [editedEmergencyContact, setEditedEmergencyContact] = useState("");
    

    const [addEmergencyContactDialog, setAddEmergencyContactDialog] = React.useState(false);

    if (profile.error) return <pre>{profile.error.message}</pre>

    return (
        <React.Fragment>
            <Stack spacing={2}>
                <Typography variant="h2" >Profile</Typography>

                {profile.loading 
                ? <Skeleton variant="rectangular" width="100%" height="40px" />
                : <TextField 
                    size="small"
                    label="Email"
                    value={profile.data.userProfileMe.email }
                    disabled />
                }

                {/* {<Stack direction="row">InReach Email<IconButton edge="end" size="small"><HelpOutlineIcon fontSize="inherit" /></IconButton></Stack>} */}
                {profile.loading 
                ? <Skeleton variant="rectangular" width="100%" height="40px" />
                : <TextField 
                    size="small"
                    label="InReach Email"
                    value={profile.data.userProfileMe.checkinEmailEndpoint}
                    disabled 
                    InputProps={{endAdornment:
                    <InputAdornment position="end">
                        <IconButton onClick={() => {navigator.clipboard.writeText(profile.data.userProfileMe.checkinEmailEndpoint)}} edge="end"> <ContentCopyRoundedIcon fontSize="small" /> </IconButton>
                    </InputAdornment> }} />
                }

                {profile.loading 
                ? <Skeleton variant="rectangular" width="100%" height="60px" />
                : <OutlinedInputWrapper label="Emergency Contacts">
                    <Grid container>
                    { 
                        profile.data.userProfileMe.verifiedEmergencyContacts && profile.data.userProfileMe.verifiedEmergencyContacts.map((email:string) => 
                        {
                            return <><Grid xs={10}><Typography>{email}</Typography></Grid><Grid xs={2} display="flex" justifyContent="center"><Chip size="small" label="verified" color="success" variant="filled" /></Grid></>
                        })
                    }
                    { 
                        profile.data.userProfileMe.unverifiedEmergencyContacts && profile.data.userProfileMe.unverifiedEmergencyContacts.map((email:string) => 
                        {
                            return <><Grid xs={10}><Typography>{email}</Typography></Grid><Grid xs={2} display="flex" justifyContent="center"><Button size="small" onClick={()=>{requestVerifyEmergencyContact({variables:{email:email}})}}>RESEND</Button></Grid></>
                        })
                    }
                    </Grid>
                    <Button size="small" variant="outlined" onClick={()=>{setAddEmergencyContactDialog(true)}}>Add Emergency Contact</Button>
                    <Dialog open={addEmergencyContactDialog} onClose={()=>setAddEmergencyContactDialog(false)}>
                        <DialogTitle>Add Emergency Contact</DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                            Enter the email address of your emergency contact. They will be sent an email requesting confirmation.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            onChange={(event)=>setEditedEmergencyContact(event.target.value)}
                        />
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={()=>setAddEmergencyContactDialog(false)}>Cancel</Button>
                        <Button variant="contained" autoFocus onClick={()=>{addEmergencyContact({variables:{email:editedEmergencyContact}}); setAddEmergencyContactDialog(false);}}>Submit</Button>
                        </DialogActions>
                    </Dialog>
                </OutlinedInputWrapper>
                }

                <Stack spacing="0">
                    {profile.loading 
                    ? <Skeleton variant="rectangular" width="100%" height="120px" />
                    : <EditableTextField
                        label="Instructions for Emergency Contacts"
                        multiline
                        fullWidth
                        minRows={4}
                        onCommitText={(message:string)=>updateEmergencyMessage({variables:{message:message}})}
                        unsubmittedText={editedEmergencyMessage}
                        onUnsubmittedTextChange={setEditedEmergencyMessage}
                        onCancel={() => setEditedEmergencyMessage(profile.data.userProfileMe.emergencyMessage)}
                        updating={updateEmergencyMessageData.loading}
                        />
                    }
                    <Collapse margin-top="0" in={updateEmergencyMessageData.error != null}>
                        <Alert severity="error" action={<Button color="inherit" size="small" onClick={() => { updateEmergencyMessage({variables:{message:editedEmergencyMessage}}) }}>RETRY</Button>}>
                            <span>Update Failed: {updateEmergencyMessageData.error && updateEmergencyMessageData.error.message}</span>
                        </Alert>
                    </Collapse>
                </Stack>
            </Stack>
        </React.Fragment>
    );
}