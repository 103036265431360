import React, {Fragment} from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface NavigationProps {
    children?: React.ReactNode;
    navLocation: string
    onNavigationChange: (page:string) => void
  }

enum AvailableNavigationPanels
{
    history,
    trip,
    profile
}

export default function Navigation(props : NavigationProps) 
{
    return (
        <Fragment>
            <Box margin='10px' paddingBottom='56px' /*add 56px bottom padding for the bottom navigation.*/ >
                {props.children}
            </Box>
        
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={AvailableNavigationPanels[props.navLocation as keyof typeof AvailableNavigationPanels]}
                    onChange={(element, event) => props.onNavigationChange(AvailableNavigationPanels[event])}
                >
                    <BottomNavigationAction label="History" icon={<RestoreIcon />} />
                    <BottomNavigationAction label="Trip" icon={<LocationOnIcon />} />
                    <BottomNavigationAction label="Profile" icon={<FavoriteIcon />} />
                </BottomNavigation>
            </Paper>
        </Fragment>
    );
}