import React from "react";

import TextField from "@mui/material/TextField";
//import Component from "@mui/material/Component";
import {InputBaseComponentProps} from "@mui/material/InputBase"

interface OutlinedInputWrapperProps {
    children?: React.ReactNode;
    label: string
  }


// Make an empty InputComponent but strip the ref
const InputComponent = (props : InputBaseComponentProps) : JSX.Element => 
{
    const { inputRef, ...other } = props;
    // @ts-ignore
    return (<div {...other}/>);
    //<div {...other} />
}

const OutlinedInputWrapper = ( props : OutlinedInputWrapperProps) => {
  return (
    <TextField
      variant="outlined"
      label={props.label}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent
      }}
      inputProps={{ children: props.children }}
    />
  );
};
export default OutlinedInputWrapper;