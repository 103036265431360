import React, {useEffect, useState, StrictMode, Fragment} from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import AuthRoute from './Auth.tsx'
import ApolloWrapper from "./ApolloClient";

import ActivePassage from './ActivePassage.js'
import Navigation from './Navigation.tsx'
import Profile from './Profile.tsx'
import ErrorBoundary from './ErrorBoundary.tsx'

import Container from '@mui/material/Container';

import './index.css';

import stackOutputs from './stack-outputs.json';

import { Paper } from '@mui/material';
import VerifyEmergencyContact from './VerifyEmergencyContact.tsx';

function App()
{
    const [navLocation, setNavLocation] = useState("profile");
    const [/*windowWidth*/, setWindowWidth] = useState(0);

    useEffect(() =>
    {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize",updateDimensions);
    }, [])

    const updateDimensions = () => { setWindowWidth(window.innerWidth) };

    /* {windowWidth < 1024
    // Small layout
    ?
    // Big layout (same for now, but this is how you could do it.)
    // Something to think about, if the state needs to be persisted it needs
    // to be hoisted. Otherwise perhaps the element should handle responsiveness itself
    : <Navigation navLocation={navLocation} onNavigationChange={setNavLocation}/> */

    const landingPage = (window.location.pathname === "/");
    const verifyEmergencyContact = (window.location.pathname === "/verify");
    const loggedIn = (window.location.pathname === "/");
    return (
        <Fragment>
            {/* {landingPage && <LandingPage />} */}
            {verifyEmergencyContact && <VerifyEmergencyContact />}
            {loggedIn &&
                <StrictMode>
                    <AuthRoute>
                        <ErrorBoundary>
                            <ApolloWrapper>
                                <CssBaseline enableColorScheme>
                                    <Navigation navLocation={navLocation} onNavigationChange={setNavLocation}>
                                        <Container fixed maxWidth="md">
                                            <Paper className="center-column2">
                                                {navLocation === "history" && <p>History</p>}
                                                {navLocation === "trip" && <ActivePassage />}
                                                {navLocation === "profile" && <Profile />}
                                            </Paper>
                                        </Container>
                                    </Navigation>


                                    {/*Debug Stack Name display */}
                                    <Paper sx={{ position: 'fixed', top: 0, right: 0, backgroundColor: 'rgba(128, 128, 128, 0.1)' }} elevation={3}>
                                        <pre>{stackOutputs.AwsStackName}</pre>
                                    </Paper>
                                </CssBaseline>
                            </ApolloWrapper>
                        </ErrorBoundary>
                    </AuthRoute>
                </StrictMode>
            }
        </Fragment>
    );
}

// ========================================

// Todo: Does this work? Needed for MUI
//<meta name="viewport" content="initial-scale=1, width=device-width" />

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
