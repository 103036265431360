import { Card } from '@mui/material';
import React,  { useEffect, useRef } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useQuery, gql } from "@apollo/client";
import config from "./config.js"

const FILMS_QUERY = gql`
  query GetCurrentPassage($userId: ID!){
    currentPassage(userId: $userId) {
        userId
        passageId
        lastCheckinTime
        waypoints
        {
            lat
            lon
            t
        }
    }
  }
`;


export function MapContainer({ center, zoomLevel, markerList, icon }) {

    const googleMapRef = useRef(null);

    useEffect(() => {
        try {
            if(!window.google || !window.google.maps)
            {
                return;
            }
            //let bounds = new window.google.maps.LatLngBounds();

            const initGoogleMap = () => {
                return new window.google.maps.Map(googleMapRef.current, {
                    center: center,
                    zoom: zoomLevel,
                    styles: [{ stylers: [{ saturation: -100 }] }]
                });
            }

            // const createMarker = (markerObj) => new window.google.maps.Marker({
            //     position: { lat: parseFloat(markerObj.lat), lng: parseFloat(markerObj.lng) },
            //     map: googleMap,
            //     icon: {
            //         url: icon,
            //         scaledSize: new window.google.maps.Size(80, 80)
            //     },
            // });

            if(!googleMapRef.current)
            {
                googleMapRef.current = initGoogleMap();
            }

            

            // markerList.map(x => {
            //     const marker = createMarker(x);
            //     bounds.extend(marker.position);
            // });

            // if (markerList.length === 0) {
            //     initGoogleMap();
            //     bounds = new window.google.maps.LatLngBounds();
            // }
            if(googleMapRef.current)
            {
                //googleMapRef.current.fitBounds(bounds);
            }
        }
        catch (e) {
            console.log("maps", e)
        }
    })

    const render = (status) => {
        if (status === Status.LOADING) return "Loading...";
        if (status === Status.FAILURE) return "Error";
        return null;
    };

    return (
        <div>
            <div>
                <Wrapper apiKey={config.options.mapsApiKey} render={render}>
                    {/* <GISTrackingMap zoomLevel={props.zoomLevel} mapFilterByVal={props.mapFilterByVal} center={props.center} gisTrackingData={props.gisTrackingData} /> */}
                    <div ref={googleMapRef} style={{ width: "100%", height: "78vh" }} />
                </Wrapper>
            </div>
        </div>
    )
}

function ActivePassage() 
{
  //const [isLoading, setLoading] = useState(true);
  //const [movieData, setData] = useState([]);

//   async function sleep(seconds) {
//     return new Promise((resolve) =>setTimeout(resolve, seconds * 1000));
//     }

//   const getMovies = async () => {
//      try {
//       const response = await fetch('https://reactnative.dev/movies.json');
//       const json = await response.json();
//       await sleep(3);
//       setData(json.movies);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   }

//   useEffect(() => {
//     getMovies();
//   }, []);

    
  const { data, loading, error } = useQuery(FILMS_QUERY,{variables:{userId: "testStaging"}});

  if (error) return <pre>{error.message}</pre>

  return (
    <Card>  
        {/* { 
            loading
            ? <Skeleton variant="rectangular" width={210} height={118} />
            : <h2>{JSON.stringify(movieData)}</h2>
        } */}
        <div>
      <ul>
        {
            loading 
            ? "Loading" 
            : <li key={data.currentPassage.userId}>{data.currentPassage.userId}</li>
        }
      </ul>
      <p>{loading ? "Loading" : JSON.stringify(data.currentPassage)}</p>
      <MapContainer zoomLevel={12} center={{lat: -34, lng: 151}}/>
    </div>
    </Card>
  );
};


export default ActivePassage;