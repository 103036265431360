const prod = 
{
    options:
    {
        STACK: `prod`,
        mapsApiKey: "AIzaSyAU0bU7clUBECNsbZX89ye16Jq_Igy6OmM"
    }
};
const dev = 
{
    options:
    {
        STACK: `dev`,
        mapsApiKey: "AIzaSyAU0bU7clUBECNsbZX89ye16Jq_Igy6OmM"
    }
};

const config = process.env.NODE_ENV === `development` ? dev: prod;
export default config;