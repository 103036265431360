import React from 'react'
import stackOutputs from './stack-outputs.json';
import moment from 'moment';

export function getAccessToken() : string | null
{
    return localStorage.getItem("access_token");
}

export function TryAuthenticate()
{
    // Parse everything after the # in the url
    const params : any = window.location.hash.slice(1).split("&").map(x => {let kvp = x.split("="); return {key: kvp[0], value: kvp[1] }}).reduce((prev : any, cur : any) => {prev[cur.key] = cur.value; return prev;}, {});
    
    if(params.hasOwnProperty("access_token")) 
    {
        localStorage.setItem("access_token", params.access_token);
        localStorage.setItem("access_token_expiration", (Math.floor(Date.now() / 1000) + parseInt(params.expires_in)).toString());
        localStorage.setItem("id_token", params.id_token);
        console.log("Setting access_token from location hash:" + params.access_token);

        // Refresh and go to root to clear token from the url hash
        window.location.replace(window.location.pathname + window.location.search);
    }

    const expirationTime = parseInt(localStorage.getItem("access_token_expiration") || "0");
    if(expirationTime < Math.floor(Date.now() / 1000))
    {
        // Attempt to re-auth. Can we do this in the background? Maybe we should pass the URL along with it?
        window.location.replace(`${stackOutputs.HostedLoginUrl}oauth2/authorize?response_type=token&scope=email+openid&client_id=${stackOutputs.CognitoAppClientId}&redirect_uri=${encodeURIComponent(window.location.href)}`);
    }
    else
    {
        console.log(`auth_token expires ${moment.duration((expirationTime * 1000) - Date.now()).humanize(true)}`);
    }

    const accessToken = localStorage.getItem("access_token");
    console.log("Retreived access_token from local storage");

    if(accessToken == null)
    {
        window.location.replace(`${stackOutputs.HostedLoginUrl}oauth2/authorize?response_type=token&scope=email+openid&client_id=${stackOutputs.CognitoAppClientId}&redirect_uri=${encodeURIComponent(window.location.href)}`);
    }
    return (accessToken != null);
}

export function Logout()
{
    console.log("Logging out");
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token_expiration");
    window.location.replace(`${stackOutputs.HostedLoginUrl}logout?client_id=${stackOutputs.CognitoAppClientId}&logout_uri=${encodeURIComponent(window.location.pathname + window.location.search)}`);
}

const AuthRoute = ({ children } : any) => 
{
    if(!TryAuthenticate())
    {
        return null;
    }

    return <React.Fragment>{children}</React.Fragment>;
}

export default AuthRoute